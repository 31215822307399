$color_1: var(--dark-green-7);
$background-color_1: var(--light-yellow-3);
$background-color_2: var(--light-yellow-1);

.accordions {
	display: flex;
	width: 100%;
	height: 100%;
	// padding: 0px 115px;
	>.accordion-container {
		width: 100%;
		>.accordion-item {
			width: 100%;
			display: flex;
			height: 64px;
			>.accordion-header {
				height: 64px;
				width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				transition: 0.3s ease-in-out;
				border: 1px solid transparent;
				cursor: pointer;
				padding: 16px 24px 16px 16px;
				border-right: 1px solid transparent;
				&:hover {
					border-right: 1px solid var(--dark-green-1);
				}
				>svg {
					>g {
						>path {
							transition: 0.3s ease-in-out;
						}
					}
				}
				>p {
					font-size: 16px;
					font-weight: 300;
					color: $color_1;
					line-height: 150%;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					transition: 0.3s ease-in-out;
					width: 100%;
				}
			}
			>.accordion-header.active {
				border-right: 1px solid var(--dark-green-1);
				background-color: $background-color_1;
				>svg {
					>g {
						>path {
							fill: white;
						}
					}
				}
				>p {
					font-weight: 400;
				}
			}
		}
	}
	>.accordion-content {
		background-color: $background-color_2;
		border-radius: 8px;
		width: 100%;
		padding: 32px;
		>p {
			color: $color_1;
			text-align: left;
			font-size: 16px;
			font-weight: 300;
			line-height: 150%;
			letter-spacing: 1px;
		}
	}
}
@media screen and (min-width : 0px ) and (max-width : 430px) {
	.accordions {
		// padding: 0px 27px;
		flex-direction: column;
		>.accordion-container {
			>.accordion-item {
				>.accordion-header {
					padding: 12px;
					>p {
						font-size: 12px;
					}
				}
			}
		}
		>.accordion-content {
			>p {
				font-size: 12px;
			}
		}
	}
}

@media screen and (min-width : 430px ) and (max-width : 1010px) {
	.accordions {
		// padding: 0px 27px;
		// flex-direction: column;
		>.accordion-container {
			>.accordion-item {
				>.accordion-header {
					padding: 12px;
					align-items: center;
					justify-content: center;
					>p {
						font-size: 14px;
					}
				}
			}
		}
		>.accordion-content {
			>p {
				font-size: 14px;
				line-height: 200%;
			}
		}
	}
}
