
.title-large {
	font-size: 48px;
	font-weight: 600;
	line-height: 120%;
}
.title-medium {
	font-size: 40px;
	font-weight: 600;
	line-height: 120%;
}
.title-small {
	font-size: 32px;
	font-weight: 500;
	line-height: 120%;
}
.body-large {
	font-size: 24px;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: 0.2px;
}
.body-medium {
	font-size: 20px;
	letter-spacing: 0.2px;
	font-weight: 400;
	line-height: 150%;
}
.body-small {
	font-size: 16px;
	letter-spacing: 0.2px;
	font-weight: 300;
	line-height: 150%;
}
