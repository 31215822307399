* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Readex Pro", sans-serif;
}
body{
    display: flex;
    justify-content: center;
}

ol,
ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

form {
    margin: 0;
    padding: 0;
}

img {
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}