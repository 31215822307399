:root {
    /* green degrees */
    --dark-green-1: #0A5C25;
    --dark-green-2: #0D732F;
    --dark-green-3: #0F8A38;
    --dark-green-4: #12A141;
    --dark-green-5: #006622;
    --dark-green-6: #226600;
    --dark-green-7: #08451C;

    --light-green-1: #E8FCEF;
    --light-green-2: #D1FADF;
    --light-green-3: #BAF7CF;
    --light-green-4: #A3F5BE;
    --light-green-5: #DDFFCC;

    /* purple degrees */
    --dark-purple-1: #410A5C;
    --dark-purple-2: #410A5C;
    --dark-purple-3: #610F8A;
    --dark-purple-4: #7112A1;
    --dark-purple-5: #440066;

    --light-purple-1: #F6E8FC;
    --light-purple-2: #ECD1FA;
    --light-purple-3: #E3BAF7;
    --light-purple-4: #DAA3F5;
    --light-purple-5: #F6E5FF;
    --light-purple-6: #FBF5FF;

    --orange : #E5801A;
    
    /* yellow degrees */
    --dark-yellow-1: #EBEB47;

    --light-yellow-1 : #FCFCE8;
    --light-yellow-2 : #FAFAD1;
    --light-yellow-3 : #F9F9D2;
    --light-yellow-4 : #F5F5A3;

    /* sizes */
    --section-max-width: 1366px;
    --section-padding: 27px;

}