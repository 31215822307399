$background-color_1: rgba(0, 0, 0, 0.5);
$background-color_2: #00000080;

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	align-self: center;
	position: relative;
	>.overLay {
		position: absolute;
		top: 64px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $background-color_1;
		z-index: 2;
	}
	>.container {
		background-image: url(../public/images/herosection-bg.png);
		width: 100%;
		height: 687px;
		position: relative;
		margin-top: -80px;
		>.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $background-color_2;
			z-index: 0;
		}
	}
}
@media screen and (min-width : 0px) and (max-width : 430px) {
	.App {
		width: 100%;
		overflow: hidden;
		>.container {
			height: 494px;
			>.overlay {
				height: 100%;
			}
		}
	}
}
@media screen and (min-width : 430px) and (max-width : 1010px) {
	.App {
		width: 100%;
		overflow: hidden;
		>.container {
			>.overlay {
				height: 100%;
			}
		}
	}
}
