$color_1: white;
$color_2: var(--dark-yellow-1);
$background-color_1: var(--light-yellow-1);
$background-color_2: var(--dark-yellow-1);
$background-color_3: var(--dark-green-7);

.hero-section {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	>.header {
		padding: 140px 0px;
		align-self: center;
		width: 752px;
		position: relative;
		height: 452px;
		display: flex;
		align-items: flex-end;
		>h1 {
			font-size: 48px;
			font-weight: 400;
			text-align: left;
			color: $color_1;
			line-height: 120%;
			position: absolute;
			opacity: 0;
			transition: 0.3s ease-in-out;
			>span {
				color: $color_2;
				font-weight: 700;
			}
		}
		>h1.active {
			opacity: 1;
		}
	}
	>.body {
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		>.product-container {
			display: flex;
			gap: 16px;
			align-items: center;
			justify-content: center;
			width: 1000px;
			overflow: hidden;
			position: relative;
			height: 448px;
		}
		>.arrows {
			display: flex;
			gap: 16px;
			>button {
				width: 64px;
				height: 64px;
				background-color: $background-color_1;
				border-radius: 50%;
				border: none;
				transition: 0.3s ease-in-out;
				&:hover {
					background-color: $background-color_2;
				}
				&:active {
					background-color: $background-color_3;
					>svg {
						>path {
							stroke: white;
						}
					}
				}
			}
			>button.arrow-left {
				>svg {
					transform: rotate(-90deg);
				}
			}
			>button.arrow-right {
				>svg {
					transform: rotate(90deg);
				}
			}
		}
	}
}
@media screen and (min-width : 0px) and (max-width : 430px) {
	.hero-section {
		>.body {
			width: 100%;
			>.product-container {
				height: 312px;
				width: 100%;
				width: 248px;
				border-radius: 16px;
			}
			>.arrows {
				position: absolute;
				z-index: 0;
				width: 100%;
				justify-content: space-between;
				top: 72%;
				padding: 0px 15px;
			}
		}
		>.header {
			width: 376px;
			padding: 120px 0px 56px 0px;
			height: 330px;
			>h1 {
				font-size: 36px;
				text-align: center;
			}
		}
	}
}
