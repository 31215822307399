$color_1: var(--dark-green-7);
$background-color_1: var(--light-yellow-2);
$background-color_2: transparent;

.tabs-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	>.tabs {
		display: flex;
		gap: 1px;
		align-items: center;
		justify-content: flex-start;
		>.tab {
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: center;
			padding: 0px 24px;
			height: 48px;
			color: $color_1;
			font-weight: 300;
			font-size: 20px;
			line-height: 150%;
			border-radius: 16px 16px 0px 0px;
			border-bottom: 1px solid var(--dark-yellow-1);
			transition: 0.2s ease-in-out;
			cursor: pointer;
		}
		>.tab.active, >.tab:hover {
			background-color: $background-color_1;
			border-bottom: 1px solid var(--dark-green-1);
			font-weight: 400;
		}
	}
	>.tabs-content {
		width: 100%;
		>.content {
			display: none;
			>.text {
				height: 368px;
				padding: 48px 112px 24px 48px;
				background-color: $background-color_2;
				z-index: 1;
				>p {
					color: $color_1;
					font-size: 20px;
					font-weight: 300;
					line-height: 150%;
					text-align: left;
					max-width: 416px;
					word-spacing: 5px;
				}
			}
			>.icon {
				position: absolute;
				top: 0px;
				right: 384px;
				width: 176px;
				height: 176px;
				color: var(--dark-yellow-1);
			}
			>.images {
				position: relative;
				>img {
					&:first-child {
						border-radius: 0px 16px 16px 0px;
						height: 100%;
						width: 100%;
					}
					&:last-child {
						position: absolute;
						border-radius: 16px 16px 0px 0px;
						right: 192px;
						bottom: 0px;
						width: 272px;
						height: 263px;
					}
				}
			}
		}
		>.content.active {
			display: flex;
			position: relative;
			background-color: $background-color_1;
			justify-content: space-between;
			border-radius: 0px 16px 16px 16px;
			border-top: 1px solid white;
		}
	}
}
@media screen and (min-width : 0px) and (max-width : 430px) {
	.tabs-container {
		>.tabs {
			>.tab {
				padding: 0px 10px;
				font-size: 16px;
			}
		}
		>.tabs-content {
			>.content.active {
				flex-direction: column;
				justify-content: center;
				border-radius: 0px 0px 12px 12px;
				gap: 47px;
			}
			>.content {
				>.text {
					height: 376px;
					width: fit-content;
					padding: 36px 64px 47px 36px;
					background-color: $background-color_2;
				}
				>.icon {
					position: absolute;
					top: 282px;
					right: auto;
					left: 64px;
					width: 120px;
					height: 120px;
				}
				>.images {
					>img {
						&:last-child {
							position: absolute;
							right: 0px;
							bottom: 200px;
							width: 248px;
							height: 248px;
							border-radius: 12px 0px 0px 12px;
						}
						&:first-child {
							width: 100%;
							height: 100%;
							border-radius: 0px 0px 12px 12px;
						}
					}
				}
			}
		}
	}
}
