$color_1: var(--dark-green-1);
$color_2: var(--dark-green-7);
$background-color_1: white;
$background-color_2: transparent;

.message-field {
	width: 100%;
	position: relative;
	display: flex;
	background-color: $background-color_1;
	height: 48px;
	gap: 8px;
	padding: 16px;
	align-items: center;
	border-bottom: 1px solid var(--dark-yellow-1);
	transition: 0.2s ease-in-out;
	border-radius: 8px 8px 0px 0px;
	height: fit-content;
	flex-direction: column;
	justify-content: space-between;
	color: $color_1;
	transition: 0.3s ease-in-out;
	>div {
		display: flex;
		justify-content: flex-start;
		gap: 8px;
		align-items: baseline;
		width: 100%;
		>textarea {
			height: 100px;
			width: 100%;
			border: none;
			font-size: 16px;
			font-weight: 400;
			resize: none;
			&::placeholder {
				color: $color_2;
				font-weight: 300;
			}
			&:focus {
				outline: none;
			}
		}
	}
	&:focus-within {
		outline: none;
		border-bottom: 1px solid var(--dark-green-1);
	}
	>button {
		display: flex;
		gap: 8px;
		justify-content: center;
		align-items: center;
		padding: 0px 24px;
		height: 48px;
		border-radius: 24px;
		border: 0px solid var(--dark-green-7);
		box-shadow: inset 0px 0px 0px 1px var(--dark-green-7);
		font-size: 16px;
		color: $color_2;
		background-color: $background-color_2;
		align-self: flex-end;
		font-weight: 300;
		transition: 0.2s ease-in-out;
		&:hover {
			border: 20x solid var(--dark-green-7);
			box-shadow: inset 0px 0px 0px 2px var(--dark-green-7);
		}
	}
}
@media screen and (min-width : 0px ) and (max-width : 430px) {
	.message-field {
		>div {
			>textarea {
				font-size: 14px;
			}
		}
	}
}
