$color_1: var(--dark-green-7);

.benefit {
	display: grid;
	// gap: 110px;
	grid-template-columns: 1fr 1fr;
	// align-items: flex-start;
	position: relative;

	>.images {
		// width: 464px;
		height: 464px;
		display: flex;
		position: relative;

		>img {
			&:first-child {
				width: 368px;
				height: 100%;
				border-radius: 16px;
				object-fit: cover;
			}

			&:last-child {
				width: 272px;
				height: 272px;
				position: absolute;
				top: 96px;
				left: 192px;
			}
		}
	}

	>.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: -1px;

		>.header {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;

			>.lines {
				width: 88px;
				display: flex;
				flex-direction: column;

				>.line1 {
					width: 100%;
					height: 96px;
					border-left: 1px solid var(--dark-green-7);
					border-bottom: 1px solid var(--dark-green-7);
					border-radius: 0px 0px 0px 16px;
				}

				>.line2 {
					width: 100%;
					height: 96px;
					border-left: 1px solid var(--dark-green-7);
					border-top: 1px solid var(--dark-green-7);
					border-radius: 16px 0px 0px 0px;
					margin-top: -1px;
				}
			}
		}

		>.text {
			display: flex;
			flex-direction: column;
			gap: 32px;
			align-items: flex-start;
			text-align: left;
			width: 476px;
			min-height: 289px;
			margin-top: -55px;
			padding-left: 109px;
			border-left: 1px solid var(--dark-green-7);
			padding-bottom: 109px;

			>h5 {
				font-size: 24px;
				font-weight: 700;
				color: $color_1;
				line-height: 120%;
				text-align: left;
			}

			>p {
				font-size: 16px;
				font-weight: 300;
				color: $color_1;
				line-height: 150%;
				text-align: left;
			}
		}
	}
}

.benefit.left {
	// flex-direction: row-reverse;

	>.images {
		order: 2;
		justify-content: flex-end;

		>img {
			&:last-child {
				right: 192px;
				left: auto;
			}
		}
	}

	>.info {
		order: 1;
		align-items: flex-end;

		>.header {
			flex-direction: row-reverse;

			>.lines {
				>.line1 {
					border-left: 0px;
					border-right: 1px solid var(--dark-green-7);
					border-radius: 0px 0px 16px 0px;
				}

				>.line2 {
					border-left: 0px;
					border-right: 1px solid var(--dark-green-7);
					border-radius: 0px 16px 0px 0px;
				}
			}
		}

		>.text {
			align-items: flex-end;
			border-left: 0px;
			border-right: 1px solid var(--dark-green-7);
			padding-left: 0px;
			padding-right: 109px;

			>h5 {
				text-align: right;
			}

			>p {
				text-align: right;
			}
		}
	}
}

@media screen and (min-width : 0px) and (max-width : 430px) {
	.benefit {
		grid-auto-flow: column;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr;
		gap: 0;
		width: 100%;

		>.images {
			// height: 367px;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: flex-end;
			border-left: 1px solid var(--dark-green-7);

			>img {
				&:first-child {
					width: 248px;
					height: 312px;
				}

				&:last-child {
					width: 184px;
					height: 184px;
					left: 91px;
					top: 85px;
				}
			}
		}

		>.info {
			padding-top: 0px;
			width: fit-content;
			margin-left: 0px;

			>.header {
				>.lines {
					width: 56px;
					display: flex;
					flex-direction: column;

					>.line1 {
						height: 56px;
					}

					>.line2 {
						height: 56px;
					}
				}
			}

			>.text {
				width: auto;
				padding-left: 64px;
				padding-bottom: 36px;
				margin-top: -20px;
				gap: 24px;

				>h5 {
					font-size: 18px;
				}
			}
		}
	}

	.benefit.left {
		flex-direction: column;

		>.images {
			justify-content: flex-start;
			border-left: 0px;
			border-right: 1px solid var(--dark-green-7);

			>img {
				&:last-child {
					right: 91px;
					left: auto;
				}
			}
		}

		>.info {
			width: 100%;

			>.header {
				>.lines {
					width: 56px;
				}
			}

			>.text {
				padding-right: 68px;
				padding-bottom: 36px;
				margin-top: -20px;
				gap: 24px;
			}
		}
	}
}

@media screen and (min-width : 430px) and (max-width : 1010px){
	.benefit {
		display: grid;
		// gap: 110px;
		grid-template-columns: 1fr 1fr;
		// align-items: flex-start;
		position: relative;
	
		>.images {
			// width: 464px;
			height: 464px;
			display: flex;
			position: relative;
	
			>img {
				&:first-child {
					width: 300px;
					height: 100%;
					border-radius: 16px;
					object-fit: cover;
				}
	
				&:last-child {
					width: 230px;
					height: 230px;
					position: absolute;
					top: 96px;
					left: 130px;
				}
			}
		}
	
		>.info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-left: -1px;
	
			>.header {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;
	
				>.lines {
					width: 88px;
					display: flex;
					flex-direction: column;
	
					>.line1 {
						width: 100%;
						height: 76px;
						border-left: 1px solid var(--dark-green-7);
						border-bottom: 1px solid var(--dark-green-7);
						border-radius: 0px 0px 0px 16px;
					}
	
					>.line2 {
						width: 100%;
						height: 76px;
						border-left: 1px solid var(--dark-green-7);
						border-top: 1px solid var(--dark-green-7);
						border-radius: 16px 0px 0px 0px;
						margin-top: -1px;
					}
				}
			}
	
			>.text {
				display: flex;
				flex-direction: column;
				gap: 32px;
				align-items: flex-start;
				text-align: left;
				width: 100%;
				min-height: 289px;
				margin-top: -35px;
				padding-left: 109px;
				border-left: 1px solid var(--dark-green-7);
				padding-bottom: 115px;
	
				>h5 {
					font-size: 24px;
					font-weight: 700;
					color: $color_1;
					line-height: 120%;
					text-align: left;
				}
	
				>p {
					font-size: 16px;
					font-weight: 300;
					color: $color_1;
					line-height: 150%;
					text-align: left;
				}
			}
		}
	}
	.benefit.left {
		// flex-direction: row-reverse;
	
		>.images {
			order: 2;
			justify-content: flex-end;
	
			>img {
				&:last-child {
					right: 130px;
					left: auto;
				}
			}
		}
	
		>.info {
			order: 1;
			align-items: flex-end;
	
			>.header {
				flex-direction: row-reverse;
	
				>.lines {
					>.line1 {
						border-left: 0px;
						border-right: 1px solid var(--dark-green-7);
						border-radius: 0px 0px 16px 0px;
					}
	
					>.line2 {
						border-left: 0px;
						border-right: 1px solid var(--dark-green-7);
						border-radius: 0px 16px 0px 0px;
					}
				}
			}
	
			>.text {
				align-items: flex-end;
				border-left: 0px;
				border-right: 1px solid var(--dark-green-7);
				padding-left: 0px;
				padding-right: 109px;
	
				>h5 {
					text-align: right;
				}
	
				>p {
					text-align: right;
				}
			}
		}
	}
}