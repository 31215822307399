$color_1: var(--light-yellow-1);
$background-color_1: #08441ce6;

.nav-bar-container {
	width: 100%;
	padding: 0 var(--section-padding);
	background-color: $background-color_1;
	z-index: 2;
	display: flex;
	justify-content: center;
	position: sticky;
	top: 0;

	>.nav-bar {
		max-width: var(--section-max-width);
		position: relative;
		height: 80px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		>.logo {
			>a {
				font-size: 24px;
				text-align: left;
				color: $color_1;
				font-weight: 400;

				>span {
					font-weight: 700;
				}
			}
		}

		>.menu {
			display: flex;
			align-items: center;
			gap: 32px;
		}

		>.cta {
			display: flex;
			gap: 8px;
			align-items: center;
		}

		>.menu-phone {
			display: none;

			>div {
				>.menu {
					display: none;
				}

				>.cta {
					display: none;
				}
			}
		}
	}
}

@media screen and (min-width : 0px) and (max-width : 430px) {
	.nav-bar-container {
		>.nav-bar {
			position: relative;
			height: 60px;

			>.menu {
				display: none;
			}

			>.cta {
				display: none;
			}

			>.menu-phone {
				display: flex;

				>span {
					cursor: pointer;
					width: 36px;
				}

				>div {
					position: absolute;
					width: calc(100% + (2 * var(--section-padding)));
					display: flex;
					flex-direction: column;
					gap: 24PX;
					padding: 30px 27px;
					background-color: $background-color_1;
					top: 60px;
					left: calc(100% + var(--section-padding));
					z-index: 2;
					align-items: center;
					transition: 0.4s ease-in-out;

					>.menu {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 10px;
					}

					>.cta {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 12px;
					}
				}

				>div.active {
					left: calc(0px - var(--section-padding));
				}
			}
		}
	}
}



@media screen and (min-width : 430px) and (max-width : 1010px) {
	.nav-bar-container {
		>.nav-bar {
			position: relative;
			height: 60px;

			>.menu {
				display: none;
			}

			>.cta {
				display: none;
			}

			>.menu-phone {
				display: flex;

				>span {
					cursor: pointer;
					width: 36px;
				}

				>div {
					position: absolute;
					width: calc(100% + (2 * var(--section-padding)));
					display: flex;
					flex-direction: column;
					gap: 24PX;
					padding: 30px 27px;
					background-color: $background-color_1;
					top: 60px;
					left: calc(100% + var(--section-padding));
					z-index: 2;
					align-items: center;
					transition: 0.3s ease-in-out;

					>.menu {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 10px;
					}

					>.cta {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 12px;
					}
				}

				>div.active {
					left: calc(0px - var(--section-padding));
				}
			}
		}
	}
}