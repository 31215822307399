$background-color_4: var(--light-yellow-2);
$color_2: var(--dark-green-7);

.mention-container {
    padding: 0px var(--section-padding);
    width: 100%;
    background-color: $background-color_4;
    display: flex;
    justify-content: center;
    >.mention {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: var(--section-max-width);
        height: 64px;
        align-items: center;
        // padding: 0px 115px;

        >.copyright {
            font-size: 16px;
            color: $color_2;
            font-weight: 300;
            line-height: 150%;
            text-align: left;
        }

        >.menu {
            display: flex;
            gap: 32px;
        }
    }
}

@media screen and (min-width : 0px) and (max-width : 430px) {
     .mention-container{
        >.mention {
			flex-direction: column;
			padding: 24px 0px;
			gap: 16px;
			height: 116px;
			justify-content: center;

			>.copyright {
				font-size: 12px;
			}

			>.menu {
				>button {
					>a {
						font-size: 12px;
					}
				}
			}
		}
     }
}