$color_1: var(--dark-green-1);
$color_2: var(--dark-green-7);
$background-color_1: var(--light-yellow-1);
$background-color_2: white;
$background-color_3: var(--light-yellow-4);
$background-color_4: var(--light-yellow-2);

.footer-container {
	padding: 0px var(--section-padding);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $background-color_1;

	>.footer {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: var(--section-max-width);
		gap: 80px;
		
		>.footer-body {
			padding: 64px 0px;
			display: flex;
			gap: 17px;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;

			>.footer-left {
				width: 367px;
				display: flex;
				flex-direction: column;
				gap: 77px;

				>div {
					&:first-child {
						display: flex;
						flex-direction: column;
						gap: 24px;
						align-items: flex-start;

						>.logo {
							font-size: 24px;
							text-align: left;
							color: $color_1;
							font-weight: 400;

							>span {
								font-weight: 700;
							}
						}

						>p {
							text-align: left;
							color: $color_1;
							font-size: 14px;
							font-weight: 300;
							line-height: 150%;
							max-width: 271px;
						}
					}
				}

				>div.menu {
					display: flex;
					gap: 24px;
					flex-direction: column;
					max-width: 345px;

					>button {
						>a {
							color: $color_2;

							>p {
								>svg {
									>g {
										>path {
											stroke: var(--dark-green-7);
											fill: white;
										}
									}
								}
							}

						}

						&:hover {
							>a {
								>p {
									>svg {
										>g {
											>path {
												stroke: var(--dark-green-7);
												fill: var(--dark-yellow-1);
											}
										}
									}
								}
							}
						}
					}

					>button.active {
						>a {
							>p {
								>svg {
									>g {
										>path {
											stroke: var(--dark-green-7);
											fill: var(--dark-yellow-1);
										}
									}
								}
							}
						}
					}
				}
			}

			>.other-info {
				display: flex;
				flex-direction: column;
				gap: 24px;
				justify-content: flex-start;
				align-items: flex-start;
				width: 367px;

				>li {
					display: flex;
					gap: 8px;
					max-width: 240px;
					display: flex;
					align-items: center;

					>a {
						font-size: 16px;
						text-align: left;
						font-weight: 300;
						color: $color_1;
					}

					>span {
						width: 24px;
						min-width: 24px;
						height: 24px;
						border-radius: 4px;
						background-color: $background-color_2;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 0.3s ease-in-out;
					}

					&:hover {
						span {
							background-color: $background-color_3;
						}
					}
				}
			}

			>form {
				display: flex;
				flex-direction: column;
				gap: 16px;
				width: 368px;

				>button {
					width: 100%;
				}
			}
		}
	}

}

@media screen and (min-width : 0px) and (max-width : 430px) {
	.footer-container {
		overflow: hidden;

		.footer {
			padding: 48px 0px;

			>.footer-body {
				gap: 36px;
				flex-direction: column;
				padding: 0;

				>.footer-left {
					width: 100%;
					>div.menu {
						display: none;
					}

					>div {
						&:first-child {
							gap: 18px;

							>p {
								max-width: 100%;
								font-size: 11px;
								text-align: left;
							}
						}
					}
				}

				>.other-info {
					gap: 18px;
					width: 100%;

					>li {
						max-width: 100%;

						>a {
							font-size: 12px;
						}
					}
				}

				>form {
					width: 100%;
				}
			}
		}

		>.footer-foot {
			flex-direction: column;
			padding: 24px 0px;
			gap: 16px;
			height: 116px;
			justify-content: center;

			>.copyright {
				font-size: 12px;
			}

			>.menu {
				>button {
					>a {
						font-size: 12px;
					}
				}
			}
		}
	}

}

@media screen and (min-width : 430px) and (max-width : 1010px) {
	.footer-container {
		overflow: hidden;

		.footer {
			padding: 48px 27px;

			>.footer-body {
				gap: 36px;
				flex-direction: column;

				>.footer-left {
					width: 100%;
					>div.menu {
						display: none;
					}

					>div {
						&:first-child {
							gap: 18px;

							>p {
								max-width: 100%;
								font-size: 14px;
								text-align: left;
							}
						}
					}
				}

				>.other-info {
					gap: 18px;
					width: 100%;

					>li {
						max-width: 100%;

						>a {
							font-size: 14px;
						}
					}
				}

				>form {
					width: 100%;
				}
			}
		}

		>.footer-foot {
			flex-direction: column;
			padding: 24px 0px;
			gap: 16px;
			height: 116px;
			justify-content: center;

			>.copyright {
				font-size: 14px;
			}

			>.menu {
				>button {
					>a {
						font-size: 14px;
					}
				}
			}
		}
	}

}