$color_1: var(--dark-green-7);
$background-color_1: white;
$background-color_2: var(--light-yellow-1);

.review-content {
	display: flex;
	height: fit-content;
	min-width: 368px;

	>.review {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: flex-end;
		padding-top: 40px;

		>.review-header {
			width: 269px;
			height: 80px;
			padding: 0px 8px;
			background-color: $background-color_1;
			position: absolute;
			top: 0;

			>.info-container {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				background-color: $background-color_2;
				border-radius: 8px;
				height: 100%;
				width: 100%;

				>img {
					width: 80px;
					height: 80px;
					border-radius: 8px 0px 0px 8px;
				}

				>.info {
					padding: 0px 32px 16px 16px;
					display: flex;
					gap: 12px;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-start;
					height: 100%;
					width: 100%;

					>p {
						font-size: 15px;
						color: $color_1;
						font-weight: 400;
						letter-spacing: 1px;
						text-transform: capitalize;
					}
				}
			}
		}

		>.comment {
			height: 100%;
			width: 100%;
			border: 1px solid var(--dark-green-7);
			border-radius: 16px;
			padding: 72px 48px 48px 48px;

			>p {
				height: 100%;
				width: 100%;
				color: $color_1;
				font-size: 16px;
				font-weight: 300;
				line-height: 150%;
				letter-spacing: 1px;
				text-align: left;
				// overflow: hidden;
			}
		}
	}

	>.review.reverse {
		align-self: flex-start;
		padding-bottom: 40px;
		padding-top: 0px;

		>.comment {
			padding: 48px 48px 72px 48px;
		}
	}
}

.review.reverse {
	>.review-header {
		bottom: 0;
		top: auto;
	}
}

@media screen and (min-width : 0px) and (max-width : 430px) {
	.review-content {
		height: 320px;
		min-width: auto;

		>.review {
			width: 248px;

			>.review-header {
				height: 60px;
				width: 190px;
				top: 0px;

				>.info-container {
					>img {
						width: 60px;
						height: 60px;
					}

					>.info {
						padding: 0px 12px 12px 12px;

						>p {
							font-size: 10px;
						}

						>img {
							height: 12px;
						}
					}
				}
			}

			>.comment {
				padding: 48px 36px 36px 36px;

				>p {
					font-size: 12px;
					text-align: center;
				}
			}
		}

		>.review.reverse {
			>.review-header {
				top: auto;
				bottom: 0px;
			}

			>.comment {
				padding: 36px 36px 48px 36px;
			}

			align-self: flex-end;
		}
	}
}