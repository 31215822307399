
.curved-line {
	display: none;
}
@media screen and (min-width : 0px) and (max-width : 430px) {
	.curved-line {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		flex-direction: column;
		// padding: 0px 27px;
	}
	.curved-line.none {
		display: none;
	}
	.curved-line.left-to-right {
		>div {
			&:first-child {
				width: 96%;
				border-bottom: 1px solid var(--dark-green-7);
				border-left: 1px solid var(--dark-green-7);
				border-radius: 0px 0px 0px 12px;
				height: 50%;
				align-self: flex-start;
			}
			&:last-child {
				height: 55%;
				width: 96%;
				align-self: flex-end;
				border-top: 1px solid var(--dark-green-7);
				border-right: 1px solid var(--dark-green-7);
				border-radius: 0px 12px 0px 0px;
				margin-top: -1px;
			}
		}
	}
	.curved-line.right-to-left {
		>div {
			&:first-child {
				width: 96%;
				border-left: 0px;
				border-bottom: 1px solid var(--dark-green-7);
				border-right: 1px solid var(--dark-green-7);
				border-radius: 0px 0px 12px 0px;
				height: 50%;
				align-self: flex-end;
			}
			&:last-child {
				height: 55%;
				width: 96%;
				align-self: flex-start;
				border-top: 1px solid var(--dark-green-7);
				border-left: 1px solid var(--dark-green-7);
				border-right: 0px;
				border-radius: 12px 0px 0px 0px;
				margin-top: -1px;
			}
		}
	}
}
