$color_1: var(--dark-green-7);
$background-color_1: white;

.field {
	width: 100%;
	position: relative;
	display: flex;
	background-color: $background-color_1;
	height: 48px;
	gap: 8px;
	padding: 0px 16px;
	align-items: center;
	border-bottom: 1px solid var(--dark-yellow-1);
	transition: 0.2s ease-in-out;
	border-radius: 8px 8px 0px 0px;
	>input {
		width: 100%;
		height: 100%;
		font-size: 16px;
		font-weight: 400;
		color: $color_1;
		transition: 0.3s ease-in-out;
		outline: none;
		border: none;
		&::placeholder {
			color: $color_1;
			font-weight: 300;
		}
	}
	&:focus-within {
		outline: none;
		border-bottom: 1px solid var(--dark-green-1);
	}
}
@media screen and (min-width : 0px ) and (max-width : 430px) {
	.field {
		>input {
			font-size: 14px;
		}
	}
}
