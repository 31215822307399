$color_1: var(--dark-green-7);
$color_2: var(--dark-yellow-1);
$background-color_1: var(--light-yellow-1);
$background-color_2: var(--dark-yellow-1);
$background-color_3: var(--dark-green-7);

.form-parent {
	width: 100%;
	padding: 0px var(--section-padding);
	display: flex;
	justify-content: center;
}
.form-container {
	display: flex;
	padding: 80px 96px 0px 0px;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	max-width: var(--section-max-width);
	background-color: $background-color_1;
	position: relative;
	border-radius: 16px;
	gap: 16px;
	>div {
		display: flex;
		flex-direction: column;
		width: 560px;
		gap: 68px;
		align-items: flex-start;
		>h2 {
			color: $color_1;
			text-align: left;
			padding-left: 96px;
		}
		>div {
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			>.bg {
				width: 100%;
				height: 192px;
				border-radius: 0px 16px 0px 0px;
				background-color: $background-color_2;
				position: absolute;
				bottom: 0px;
				left: 0px;
			}
			>img {
				border-radius: 16px 16px 0px 0px;
				position: relative;
				z-index: 0;
				height: 272px;
				width: 368px;
				object-fit: cover;
			}
		}
	}
	>form {
		width: 368px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		>.field-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
		>button {
			width: 100%;
			background-color: $background-color_3;
			border: 1px solid var(--dark-green-7);
			>a {
				color: $color_2;
			}
		}
	}
	>h2 {
		display: none;
	}
	>.img {
		display: none;
	}
}
@media screen and (min-width : 0px) and (max-width : 430px) {
	.form-parent {
		padding: 0px 27px;
	}
	.form-container {
		flex-direction: column;
		padding: 0px;
		border-radius: 12px;
		>div {
			display: none;
		}
		>h2 {
			display: flex;
			color: $color_1;
			text-align: center;
			font-size: 24px;
			padding: 30px;
		}
		>form {
			padding: 0px 30px;
			width: 100%;
		}
		>.img {
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			padding: 56px 0px 0px 0px;
			>.bg {
				width: 100%;
				height: 192px;
				border-radius: 0px 0px 12px 12px;
				background-color: $background-color_2;
				position: absolute;
				bottom: 0px;
				left: 0px;
			}
			>img {
				border-radius: 12px 12px 0px 0px;
				position: relative;
				z-index: 0;
				height: 100%;
				height: 248px;
				object-fit: fill;
				align-self: center;
			}
		}
	}
}
